<template>
  <Langs />
  <LoadingBar />
  <AppLayout>
    <router-view />
  </AppLayout>
</template>

<script>
import AppLayout from '@/layouts/AppLayout' //https://itnext.io/vue-tricks-smart-layouts-for-vuejs-5c61a472b69b

import Langs from "@/components/layout/Langs"
import LoadingBar from "@/components/layout/LoadingBar"

export default {
  name: 'App',
  components: { 
    AppLayout, 
    Langs, 
    LoadingBar 
  },
  mounted() {
    document.dispatchEvent(new Event('render-event'));
  },
}
</script>

<style lang="scss">
@import '@/assets/fontawesome-pro-6.0.0-beta1-web/scss/fontawesome';
@import '@/assets/fontawesome-pro-6.0.0-beta1-web/scss/light.scss';
@import '@/assets/fontawesome-pro-6.0.0-beta1-web/scss/regular.scss';

@import '@/assets/scss/main.scss';
@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import "@/framework/components/forms/scss/forms.scss";
</style>
