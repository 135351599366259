import { ClassFactory } from './classFactory'

class Classes {

	constructor(ApiRootUrl) {
        this.$factory = new ClassFactory(ApiRootUrl);
    }

	async loadAuthenticated({token}) {
        if (token) this.$factory.setBearerToken(token);
        const classes = await this.$factory.loadClasses('/meta/classes');
        Object.assign(this, classes);
        return this;
	}

	async loadPublic() {
        this.$factory.setBearerToken(null);
        const classes = await this.$factory.loadClasses('/meta/public_classes');
        Object.assign(this, classes);
        return this;
    }
}

export default Classes
export { Classes }