<template>
    <div class="langs" v-if="show">
        <button 
            v-for="it in $i18n.availableLocales" 
            :key="'l_c_'+it" 
            class="lang" 
            :class="{'lang--active': it == $i18n.locale}"
            @click="$i18n.locale = it"
            v-html="getEmojiFlag(it)"
            :aria-label="it + ' lang'"
        ></button>
    </div>
</template>

<script>
export default {
    computed: {
        env(){
            return process.env.NODE_ENV
        },
        show(){
            // return this.$route.query.langDevSelect
            return this.$route.query.langDevSelect || this.env === 'development'
        }
    },
    methods: {
        getEmojiFlag(locale){
            switch (locale) {
                case 'lv-LV':
                    return `<svg xmlns="http://www.w3.org/2000/svg" id="flag-icon-css-lv" viewBox="0 0 512 512">
                        <g fill-rule="evenodd">
                            <path fill="#fff" d="M0 0h512v512H0z"/>
                            <path fill="#981e32" d="M0 0h512v204.8H0zm0 307.2h512V512H0z"/>
                        </g>
                    </svg>
                    `;
                case 'pl-PL':
                    return `<svg xmlns="http://www.w3.org/2000/svg" id="flag-icon-css-pl" viewBox="0 0 512 512">
                        <g fill-rule="evenodd">
                            <path fill="#fff" d="M512 512H0V0h512z"/>
                            <path fill="#dc143c" d="M512 512H0V256h512z"/>
                        </g>
                    </svg>
                    `;
                case 'en-US':
                    return `<svg xmlns="http://www.w3.org/2000/svg" id="flag-icon-css-gb" viewBox="0 0 512 512">
                        <path fill="#012169" d="M0 0h512v512H0z"/>
                        <path fill="#FFF" d="M512 0v64L322 256l190 187v69h-67L254 324 68 512H0v-68l186-187L0 74V0h62l192 188L440 0z"/>
                        <path fill="#C8102E" d="M184 324l11 34L42 512H0v-3l184-185zm124-12l54 8 150 147v45L308 312zM512 0L320 196l-4-44L466 0h46zM0 1l193 189-59-8L0 49V1z"/>
                        <path fill="#FFF" d="M176 0v512h160V0H176zM0 176v160h512V176H0z"/>
                        <path fill="#C8102E" d="M0 208v96h512v-96H0zM208 0v512h96V0h-96z"/>
                        </svg>
                    `;
                default:
                    return '?';
            }
        }
    }
}
</script>

<style lang="scss" scoped>
// $flag-icon-css-path: '../../../node_modules/flag-icon-css/flags';
// @import '../../../node_modules/flag-icon-css/sass/flag-icon.scss';

</style>

<style lang="scss" scoped>
@import '@/assets/scss/colors',
        '@/assets/scss/breakpoints';

.langs{
    position: fixed;
    top: 0;
    right: 40vw;

    display: flex;

    z-index: 1000;

    @media (max-width: $screen-sm-1) {
        flex-direction: column;

        position: fixed;
        
        top: calc(50vh - 100px);

        right: 0;
    }

    background: rgb(242, 242, 242);
    border-radius: 5px;

    .lang{
        padding: 5px 5px;
        margin: 5px 2px;

        border: none;
        background: none;
        
        border-radius: 5px;



        &--active, &:hover{
            background-color: rgb(182, 182, 182);
        }

        &::v-deep>svg{
            height: 20px;
        }
    }
}
</style>