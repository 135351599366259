/* eslint-disable */ 

import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { $classes } from '@/main'

@Module({ namespaced: true })
class Panel extends VuexModule {
    prizes = null
    applications = null
    orders = null

    newApplication = null
    
    walletType = 'house_lv'

    prizesIsFetching = false
    applicationsIsFetching = false
    ordersIsFetching = false

    @Mutation
    setPrizes(data) {
        this.prizes = data;
        this.prizesIsFetching = false;
    }
    @Mutation
    setOrders(data) {
        this.orders = data;
        this.ordersIsFetching = false;
    }
    @Mutation
    setApplications(data) {
        this.applications = data;
        this.applicationsIsFetching = false;
    }
    @Mutation
    setNewApplication(data) {
        this.newApplication = data;
    }
    @Mutation
    setPrizesProgress() {
        this.prizesIsFetching = true;
    }
    @Mutation
    setApplicationsProgress() {
        this.applicationsIsFetching = true;
    }
    @Mutation
    setOrdersProgress() {
        this.ordersIsFetching = true;
    }

    @Action({rawError: true})
    async get_prizes() {
        if(this.prizesIsFetching) return;
        this.context.commit('setPrizesProgress');
        let data = await $classes.BuilderPrize.energomocni_get_list({ 'type': this.walletType });
        this.context.commit('setPrizes', data);
        return data;
    }
    @Action({rawError: true})
    async get_orders() {
        if(this.ordersIsFetching) return;
        this.context.commit('setOrdersProgress');
        let data = await $classes.BuilderOrder.lb_get_list();
        this.context.commit('setOrders', data);
        return data;
    }
    @Action({rawError: true})
    async get_applications() {
        if(this.applicationsIsFetching) return;
        this.context.commit('setApplicationsProgress');
        let data = await $classes.BuilderApplication.energomocni_get_applications();
        this.context.commit('setApplications', data);
        return data;
    }
}

export default Panel;