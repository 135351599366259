/* eslint-disable */

import { Module, VuexModule, Mutation } from 'vuex-module-decorators'

@Module({ namespaced: true })
class Registration extends VuexModule {
    form_data = null;
    @Mutation
    setData(data) {
        this.form_data = data;
    }
}

export default Registration;