/* eslint-disable no-unused-vars */

import axios from 'axios';

class ClassFactory {

	constructor(ApiRootUrl) {
		this.ApiRootUrl = ApiRootUrl;
		this.$http = axios.create(this.getAxiosConfig())
		this.urlToClasses = {};
		this.token = null;
	}

	getAxiosConfig() {
		let config = {
			headers: {}
		}
		if (this.token) config.headers['Authorization'] = 'Bearer ' + this.token; 
		return config;
	}

	setBearerToken(token) {
		this.token = token;
		this.$http = axios.create(this.getAxiosConfig())
	}


	async loadClasses(url) {
		if (url in this.urlToClasses) {
			return this.urlToClasses[url];
		}

		let absolute_url = this.ApiRootUrl + (url || '/meta/classes');
		try {
			const response = await this.$http.get(absolute_url);
			let info = typeof response.data == "string" ? JSON.parse(response.data) : response.data;
			console.debug("Loaded url", url);

			let classes = {};
			this.makeClasses(info, classes);

			this.urlToClasses[url] = classes;

			return classes;
		} catch (error) {
			// failed to load classes
			console.debug("Failed to load url", url, error);
			throw error;
		}
	}

	classnameToPath(classname) {
		return classname.replace(/([A-Z])/g, '-$1').replace(/^-/, '').toLowerCase();
	}

	getInstance(classes, data) {
		let classname = data.__class;
		if (!classname) {
			throw "getInstance: No __class property in object";
		}
		if (!(classname in classes)) {
			//throw "getInstance: Unknown class: " + classname;
			console.warn("getInstance: Unknown class: " + classname + ". Returning raw data...")
			return data;
		}
		if (typeof data.__id == "undefined" && typeof data.__vid == "undefined") {
			//throw "No __id in data to instantiate class: " + classname;
			return classes[classname].create(data);
		}
		if ((data.__vid || data.__id) === null) {
			return null;
		}
		let obj = classes[classname].get(data.__vid || data.__id, data);
		return obj;
	}

	activateClasses(classes, data) {
		if (data instanceof Object) {
			if (Array.isArray(data)) {
				for (let i = 0; i < data.length; i++) {
					if (data[i].__class) {
						data[i] = this.getInstance(classes, data[i]);
					}
				}
			} else if (data.__class) {
				data = this.getInstance(classes, data);
			} else {
				for (let key in data) {
					if (data[key] instanceof Object && data[key].__class) {
						data[key] = this.getInstance(classes, data[key]);
					} else {
						this.activateClasses(classes, data[key]);
					}
				}
			}
		}
		return data;
	}

	// wymuś ponowne pobranie obiektów, po zapisie do bazy
	setDirty(classes) {
		for (let [classname, klass] of Object.entries(classes)) {
			for (let [id, obj] of Object.entries(klass.cache)) {
				if (obj.__fetched) delete obj.__fetched;
			}
		}
	}

	makeClasses(info, classes) {

		for (let [classname, classinfo] of Object.entries(info)) {

			if (classname[0] == '$') return;  // Angular stuff
			classes[classname] = this.makeClass(classname, classinfo, classes);

		}
		return classes;
	}


	makeClass(classname, classinfo, classes) {
		let factory = this;
		let klass;

		console.debug('Creating class:', classname);

		try {
			eval(`klass = function ${classname}() {}`);
			//klass = eval(classname);
		}
		catch (e) {
			klass = function () { };
		}

		// Class information
		for (let [key, value] of Object.entries(classinfo)) {
			klass[key] = value;
		}

		// Static methods
		klass.classname = classname;

		klass.cache = {};

		const deepcopy = obj => JSON.parse(JSON.stringify(obj))


		// Datastore methods
		klass.get = (vid, data = null) => {
			if (data && data.__classname) {
				// actual class instance
				return data;
			}
			if (!klass.cache[vid]) {
				klass.cache[vid] = new klass();
				data = data || { __vid: vid };
				data.__class = klass.classname;
			}
			let obj = klass.cache[vid];
			if (data && typeof data == "object") obj.fill(data);
			return obj;
		}

		// create new instance of the class
		klass.create = (data = null) => {
			if (data && data.__classname) {
				// actual class instance
				return data;
			}
			let obj = new klass();
			// assign perms
			if (klass.perms && klass.perms.create) {
				obj.__perms = klass.perms.create;
			}
			// fill data
			if (data) {
				if (data.__vid || data.__id || data.id) {
					throw "Class create: data contains id information";
				}
				data.__class = klass.classname;
				obj.fill(data);
			}
			return obj;
		}

		klass.getObjectPayload = (obj, group = null, omit_fieldname = null) => {
			let payload = {};
			if (obj.__vid) payload.__vid = obj.__vid;
			if (obj.__id) payload.__id = obj.__id;
			if (obj.__class) payload.__class = obj.__class;
			if (obj.__title) payload.__title = obj.__title;
			for (let field of klass.get_fields(group)) {
				let fieldname = field.name;
				let value = obj[fieldname];
				if (typeof value == "undefined") continue;

				if (fieldname == omit_fieldname) continue;

				if (!field.join) {
					payload[fieldname] = value;  // not a reference type
				}

				if (field.join && field.join.type == 'foreignKey') {
					if (value && (value.__vid != null || value.__id != null || value.id != null || value.__class != null)) {
						payload[fieldname] = {}
						if (value.getClass) payload[fieldname] = value.getClass().getObjectPayload(value, group || 'essential');
						else if (value.__class && classes[value.__class]) payload[fieldname] = classes[value.__class].getObjectPayload(value, group || 'essential');
						if (value.__CONTEXT_OBJECT__) payload[fieldname].__CONTEXT_OBJECT__ = value.__CONTEXT_OBJECT__;
					} else {
						payload[fieldname] = value;
					}
				}

				if (field.join && field.join.type == 'backRef') {
					var ret = [];
					if (value && value.length >= 0) {
						for (let item of value) {
							ret.push(classes[field.join.foreignClass].getObjectPayload(item, group, field.join.backref));
						}
					}
					payload[fieldname] = ret;
				}
			}
			return payload;
		}

		klass.updateObject = (obj, group = null, payload = null) => {
			// TODO - validation
			let orig_vid = obj.__vid;
			if (group && typeof group == 'object') group = JSON.stringify(group);
			let config = {
				method: 'post',
				url: klass.ApiRootPath + (orig_vid ? ('/' + orig_vid) : '') + (group ? ('?group=' + group) : ''),
				data: payload || klass.getObjectPayload(obj)
			}
			return factory.$http(config).then((result) => {

				factory.setDirty(classes);  // kasuj wszystko, nie wiadomo co się zmieniło :)

				obj.fill(result.data, true);  // aktualizuje backref, ale poprzez wymianę niezapisanych obiektów
				obj.__fetched = Promise.resolve(obj);
				if (!orig_vid) {
					// utworzono nowy obiekt - aktualzuj cache
					klass.cache[obj.__vid] = obj;
				}

				return obj;
			});
		}

		klass.deleteObject = (obj) => {
			let orig_vid = obj.__vid;
			let config = {
				method: 'delete',
				url: klass.ApiRootPath + (orig_vid ? ('/' + orig_vid) : ''),
			}
			return factory.$http(config).then((result) => {
				delete obj.__fetched;
				if (!orig_vid) {
					// skasowano obiekt - aktualzuj cache
					delete klass.cache[obj.__vid];
				}

				factory.setDirty(classes);  // kasuj wszystko, nie wiadomo co się zmieniło :)

				return true;
			});
		}

		klass.find = (params) => {
			let config = {
				method: 'get',
				url: klass.ApiRootPath,
				params: params
			}
			return factory.$http(config).then((result) => {
				let ret = [];
				if (Array.isArray(result.data)) {
					for (let item of result.data) {
						ret.push(factory.getInstance(classes, item));
					}
				}
				return ret;
			});
		}

		let intersection = (a1, a2) => {
			let res = [];
			for (let item of a1) {
				if (a2.indexOf(item) >= 0) res.push(item);
			}
			return res;
		}




		// Api methods
		klass.get_fields = (group) => {
			group = typeof group == "string" ? [group] : group;
			var fields = [];
			for (var fname in klass.fields) {
				if (group == null || intersection(klass.fields[fname].groups || [], group).length > 0) fields.push(klass.fields[fname]);
			}
			return fields;
		}

		// pobieranie listy metod (z opcjonalnym filterm grupy)
		klass.get_methods = (group) => {
			group = typeof group == "string" ? [group] : group;
			var methods = [];
			// class/object methods
			for (let fname in klass.methods) {
				if (group == null || intersection(klass.methods[fname].groups || [], group).length > 0) {
					let m = klass.methods[fname];
					m.path = m.field ? (m.field + '.' + m.name) : m.name;
					methods.push(m);
				}
			}

			return methods;
		}

		klass.get_form_params = function (object = null, fields = null, perms = null) {
			var operms;
			if (object) {
				operms = perms || object.__perms || {};
			} else {
				operms = perms || klass.perms.create || {};
			}
			var clsfields = fields || klass.get_fields('edit')
			var clsmethods = klass.get_methods();
			fields = [];
			for (var clsfield of clsfields) {
				var field = {
					"title": clsfield.title,
					"type": clsfield.type,
					"name": clsfield.name,
					"groups": clsfield.groups || ['edit'],
					"required": clsfield.required,
					"visible": operms[clsfield.viewperm] ? (clsfield.enable || 'true') : 'false',
					"editable": operms[clsfield.editperm] ? 'true' : 'false',
					"default": clsfield['default'],
					"redefault_on": (clsfield.client || {}).redefault_on || 'never',
					"client": clsfield.client,
					// "dictionary"
					// "dict"
					// "depends"
					// "fields"
				}
				if (clsfield.depends) {
					field.depends = clsfield.depends;
				}
				if (clsfield.dictionary) {
					field.dictionary = clsfield.dictionary;
				}
				if (clsfield.join) {
					// ReferenceField or BackReferenceField or GridReferenceField
					field.join = clsfield.join;
					if (clsfield.type == "BackReferenceField" || clsfield.type == "GridReferenceField") {
						if (!classes[field.join.foreignClass]) {
							throw "Invalid ref, no foreignClass: " + field.join.foreignClass;
						}
						let subfields = classes[field.join.foreignClass].get_fields((field.client || {}).editgroup || 'edit')
						field.fields = classes[field.join.foreignClass].get_form_params(null, subfields, perms)
					}
				}
				if (clsfield.client && clsfield.client.fields && typeof clsfield.client.fields.length == "number") {
					field.fields = klass.get_form_params(object, clsfield.client.fields, perms);
				}
				if (clsfield.client && clsfield.client.actions && typeof clsfield.client.actions.length == "number") {
					let actions = [];
					for (let action of clsfield.client.actions) {
						action.form = klass.get_form(action.formname);
						actions.push(action);
					}
					field.client.actions = actions;
				}
				// "dict" and other methods
				/*for (var clsmethod of clsmethods) {
					if (clsmethod.field == (clsfield.path||clsfield.name)) {
						// bind method to the field
						if (clsmethod.is_static) {
							field[clsmethod.name] = klass[clsmethod.path.replace('.', '__')];
						} else if (object) {
							field[clsmethod.name] = object[clsmethod.path.replace('.', '__')];
						}
					}
				}*/
				if (clsfield.methods != null && typeof clsfield.methods == "object") {
					for (let mname in clsfield.methods) {
						let funcname = clsfield.methods[mname].replace(/\./g, '__')
						field[mname] = (object && object[funcname]) || klass[funcname]  // instance or static method
					}
				}
				fields.push(field)
			}
			return fields
		}

		klass._method_to_form = (method, object) => {
			var form = {
				title: method.title,
				prompt: method.prompt,
				btn: method.title,
				templateUrl: method.templateUrl || null,  // used in form-service
				/*'name': method.name,
				'path': method.path,
				'context': method.is_static? klass : object,*/
				perm: method.perm,  // used in actions visibility
				params: [],
				layout: ['edit'],
				submit: function (values) {
					var context = method.is_static ? klass : object;
					return context[method.path.replace(/\./g, '__')](values);
				},
			}
			var fields = [];
			for (var p in method.params) {
				if (!method.params[p].field) continue;
				var field = deepcopy(method.params[p].field);
				field.path = method.path + '.' + field.name;
				fields.push(field);
			}
			var perms = method.is_static ? klass.perms : object.__perms;
			form.params = klass.get_form_params(object, fields, perms)
			return form;
		}

		klass.get_forms = (group, object) => {
			var methods = klass.get_methods(group);
			var forms = [];
			for (var i = 0; i < methods.length; i++) {
				var method = methods[i];
				if (!method.is_static && !object) continue;
				let form = klass._method_to_form(method, object);
				forms.push(form);
			}
			return forms;
		}

		klass.get_form = (method_name, object) => {
			var methods = klass.get_methods();
			var forms = [];
			for (var i = 0; i < methods.length; i++) {
				var method = methods[i];
				if (method.name == method_name) {
					return klass._method_to_form(method, object);
				}
			}
			return null;
		}

		klass.clone_instance = (original, data = null, group = 'edit') => {
			return original.fetch(false, 2).then((original) => {
				data = data || {};
				let waitables = [];
				var fields = klass.get_fields(group);
				for (let field of fields) {
					let value;
					if (!field.join) {
						value = original[field.name];  // not a reference type
					}

					if (field.join && field.join.type == 'foreignKey') {
						value = original[field.name];  // do not clone foreign key objects
					}

					if (field.join && field.join.type == 'backRef') {
						// cascade clone to editable backref objects
						value = [];
						let foreignKlass = classes[field.join.foreignClass];
						if (!foreignKlass) throw "Invalid backRef, no foreignClass: " + field.join.foreignClass;
						if (original[field.name] && original[field.name].length >= 0) {
							for (let item of original[field.name]) {
								let promise = foreignKlass.clone_instance(item, null, group).then((instance) => {
									instance[field.join.backref] = null;
									value.push(instance);
									return (parent) => { instance[field.join.backref] = parent; }
								});
								waitables.push(promise);
							}
						}
					}
					data[field.name] = value;
				}
				return factory.Promise.all(waitables).then((initializers) => {
					let instance = klass.create(data);
					for (let initializer of initializers) {
						initializer(instance);
					}
					return instance;
				});
			});
		}

		klass.get_text_search_query = () => {
			var fields = klass.get_fields('list').filter(field => klass.perms[field.listperm]);
			var textfields = ["''"];  // in case of no fields
			for (var i in fields) {
				var f = fields[i];
				if (f.type == 'TextField' || f.type == 'Field') textfields.push("coalesce(" + f.name + ",'')");
				else if (f.type == 'IntField' || f.type == 'DecimalField') textfields.push("coalesce(" + f.name + "::text,'')");
				else if (f.type == 'ReferenceField') {
					if (classes[f.join.foreignClass].fields['title']) textfields.push("coalesce(" + f.name + ".title,'')");
					else textfields.push("coalesce((" + f.sort_expr + ")::text,'')");
				}
				else if (f.type.match(/CalculatedField$/)) textfields.push("coalesce((" + f.sort_expr + ")::text,'')");
				else if (f.client && f.client.widget == 'Field' && intersection(f.groups || [], 'filter').length > 0) textfields.push("coalesce((" + f.sort_expr + ")::text,'')");
			}
			return "array_to_string(ARRAY[" + textfields.join(", ") + "], ' ') ~* ALL(string_to_array(?, ' '))";
		};

		klass.get_sortcol = (fieldname) => {
			var field = klass.fields[fieldname];
			if (!field) return null;
			return field.sort_expr;
		}

		klass.get_url = function (verb, params) {
			var to_query_string = function (obj) {
				var str = [];
				for (var p in obj) {
					var v = typeof obj[p] == "object" ? JSON.stringify(obj[p]) : obj[p];
					str.push(encodeURIComponent(p) + "=" + encodeURIComponent(v));
				}
				return str.join("&");
			}
			let url = this.ApiRootPath + '/' + verb + '?' + to_query_string(params);
			return url;
		}

		var class_path = factory.classnameToPath(classname);
		klass.ApiRootPath = `${factory.ApiRootUrl}/${class_path}`

		// remote static methods
		let methods = klass.get_methods();
		for (let method of methods) {
			if (!method.is_static || method.http['default']) continue;

			(function (method, classname) {
				var path = method.path.replace(/\./g, '__')
				//console.debug('Adding class method:', path, method);
				klass[path] = function (args) {
					var config = {
						method: method.http.method.toLowerCase(),
						url: `${klass.ApiRootPath}/${method.path}`
					}
					if (config.method == 'post' || config.method == 'put') {
						config.data = args;
					} else {
						config.params = args;
					}

					let promise = factory.$http(config).then((result) => {
						if (config.method != 'get') {
							factory.setDirty(classes);  // po metodzie POST wszystko się mogło zmienić.
						}
						let data = result.data;
						return factory.activateClasses(classes, data);
					});

					return promise;
				}
				klass[path].method = method;
			})(method, classname);
		}

		// remote instance methods
		for (let method of methods) {
			if (method.is_static || method.http['default']) continue;
			(function (method, classname) {
				var path = method.path.replace(/\./g, '__')
				var global_config = {
					method: method.http.method.toLowerCase(),
				}
				//console.debug('Adding class method:', path, method);
				klass.prototype[path] = function (args) {
					var config = Object.assign({}, global_config);
					config.url = `${klass.ApiRootPath}/${this.__vid}/${method.path}`;
					if (config.method == 'post' || config.method == 'put') {
						config.data = args;
					} else {
						config.params = args;
					}

					let promise = factory.$http(config).then((result) => {
						if (config.method != 'get') {
							factory.setDirty(classes);  // po metodzie POST/PUT/DELETE wszystko się mogło zmienić.
						}
						let data = result.data;
						return factory.activateClasses(classes, data);
					});

					return promise;
				}
				klass.prototype[path].method = method;
			})(method, classname);
		}

		let mapField = function (fieldname, value, oldvalue) {
			var field = klass.fields[fieldname];
			if (!field) {
				console.warn('Attempted to get() nonexisting field: ' + fieldname);
				return null;
			}

			if (!field.join) {
				return value;  // not a reference type
			}

			if (field.join && field.join.type == 'foreignKey') {
				if (value === null) return null;
				if (typeof value === "undefined") return null;
				if (value.__vid != null || value.__id != null) {
					return factory.getInstance(classes, value);
					//return classes[field.join.foreignClass].get(value.__vid, value);
				} else {
					return null;
				}
			}

			if (field.join && field.join.type == 'backRef') {
				var ret = oldvalue || [];
				ret.length = 0;
				if (value && value.length >= 0) {
					for (let item of value) {
						//ret.push(classes[field.join.foreignClass].get(item.__vid, item));
						ret.push(factory.getInstance(classes, item));
					}
				}
				return ret;
			}
		}


		// Instance methods
		klass.prototype.__classname = classname;





		/*
		 * This method updates the object with data provided from an external source, API
		 */
		klass.prototype.fill = function (data, force) {
			// extract metadata
			if (data.__class) this.__class = data.__class;
			if (data.__id) this.__id = data.__id;
			if (data.__vid) this.__vid = data.__vid;
			if (data.__title) this.__title = data.__title;
			if (data.__perms) this.__perms = data.__perms;
			if (data.__callstatus) this.__callstatus = data.__callstatus;
			// update actual object data
			for (let fieldname in data) {
				if (!klass.fields[fieldname]) continue;  // only actual fields
				// don't update user-modified fields, only unseen or unmodified.
				// this is important when someone has modified things, yet more information comes from API.
				if (force || klass.fields[fieldname].join || typeof this[fieldname] == "undefined" || this[fieldname] == this._original[fieldname]) {
					this[fieldname] = mapField(fieldname, data[fieldname], this[fieldname]);
				}
			}
			// update the original data cache
			this._original = this._original || {};
			Object.assign(this._original, data);
		};


		/*
		 * Rollback user changes to the object
		 */
		klass.prototype.rollback = function () {
			for (let fieldname in this._original) {
				if (!klass.fields[fieldname]) continue;  // only actual fields
				this[fieldname] = mapField(fieldname, this._original[fieldname], this[fieldname]);
			}
		};

		klass.prototype.tmp_title = function () {
			return this.title;
		}

		klass.prototype.has_perm = function (perms) {
			if (typeof perms == "undefined") {
				console.debug(`Undefined perm argument passed to ${this.__classname}(${this.__vid}).has_perm()`);
				return false;
			}
			if (typeof perms == "string") perms = [perms];
			for (var i = 0; i < perms.length; i++) {
				if (this.__perms[perms[i]]) return true;
			}
			return false;
		}

		klass.prototype.get_forms = function (group) {
			return klass.get_forms(group, this);
		}

		klass.prototype.get_form = function (method_name) {
			return klass.get_form(method_name, this);
		}

		klass.prototype.get_form_params = function (fields = null, perms = null) {
			return klass.get_form_params(this, fields, perms);
		}

		klass.prototype.parent_field = function () {
			var field;
			for (var i in klass.fields) {
				if (klass.fields[i].is_parent) {
					field = klass.fields[i];
					break;
				}
			}
			return field;
		}

		klass.prototype.get_parent = function () {
			//return null;
			var field = this.parent_field();
			if (field && this[field.name]) {
				return this[field.name];
			}
			return null;
		}

		// Zwraca tą samą instancję po pobraniu danych z serwera
		klass.prototype.fetch = function (force, levels = 1) {
			if (!this.__fetched && this._original.__loaded >= levels) {
				this.__fetched = (factory.Promise.resolve || factory.Promise.when)(this);
			}
			if (this.__fetched && !force) {
				return this.__fetched.then(() => { return this });
			}
			let config = {
				method: 'get',
				url: klass.ApiRootPath + '/' + this.__vid + (levels ? ('?levels=' + levels) : '')
			}
			return this.__fetched = factory.$http(config).then((result) => {
				this.fill(result.data);
				return this;
			});
		}

		// eg. get('landlord')
		//     get('landlord.last_name'), etc.
		klass.prototype.get = function (fieldpath) {
			var path = fieldpath.split('.');
			var fieldname = path.shift();
			return new factory.Promise((resolve, reject) => {
				let loaded
				if (typeof this[fieldname] == "undefined") {
					// field is curently not loaded
					loaded = this.fetch();
				} else {
					// field is loaded
					loaded = (factory.Promise.resolve || factory.Promise.when)(this);
				}

				loaded.then(() => {
					if (path.length > 0) {
						if (this[fieldname] === null) {
							resolve(null);
						} else {
							this[fieldname].get(path.join('.')).then(resolve, reject);
						}
					} else {
						resolve(this[fieldname]);
					}
				}, reject);
			})
		}


		/*var define_properties = function() {
			for (let field_name in klass.fields) {
				(function(field_name){
					console.log('Defining property ', field_name);
					var field = klass.fields[field_name];
					Object.defineProperty(klass.prototype, field_name, {
						get: function(){ return this.get(field_name) },
						set: function(new_value){
							this._data[field_name] = new_value;
						},
						enumerable: true,
						configurable: true,
					});
				})(field_name);
			}
		}
		define_properties()
		*/

		// alias to update?
		klass.prototype.save = function (groups = null, payload = null) {
			return klass.updateObject(this, groups, payload);
		}

		klass.prototype.delete = function () {
			return klass.deleteObject(this);
		}

		klass.prototype.getClass = function () {
			return klass;
		}

		return klass;

		// TODO
		/*
		 x usunąć define_properties, przy fill przerabiać referencje na obiekty
		 x dodać fetch() - wczytuje obiekt -> Promise.
		 X dodać metody instancji
		 * opracować metodę wykrywania zmian w obiekcie, observe?
		*/

	}

}

/* eslint-enable no-unused-vars */

export { ClassFactory }








