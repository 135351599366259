/* eslint-disable */ 

import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { $classes } from '@/main'

@Module({ namespaced: true })
class CurrentUser extends VuexModule {
    instance = null
    sms_request_id = null

    confirm_hash = null
    confirm_hash_in_progress = false
    confirm_hash_confirmed = false

    @Mutation
    setInstance(user) {
        this.instance = user;
    }

    @Mutation
    setSmsRequestId(sms_request_id) {
        this.sms_request_id = sms_request_id;
    }
    @Mutation
    setConfirmHash({confirm_hash, status}) {
        if(confirm_hash)
            this.confirm_hash = confirm_hash

        if(status == 'in_progress'){
            this.confirm_hash_in_progress = true
            this.confirm_hash_confirmed = false
        }else if(status == 'confirmed'){
            this.confirm_hash_in_progress = false
            this.confirm_hash_confirmed = true
        }
    }

    @Action
    async requireUserInfo() {
        if (this.instance == null) {
            if (!$classes.Person) {
                await $classes.loadPublic();
            }
            const user = await $classes.Person.current_user();

            if (user && user.__id) {
                await $classes.loadAuthenticated({token: null})  // cookie?
                this.context.commit('setInstance', user)
            }
        }
    }

    @Mutation
    saveAuthData(data){
        let {access_token, refresh_token, expires_in, refresh_expires_in, remember_me} = data;

        /*
            if user checked remember_me, the data is saved in localStorage, 
            or if auth data was saved earlier at localStorage and user is logging by token
        */
       
        if(remember_me || (remember_me === '?' && localStorage.getItem('access_token'))){ 
            /* localstorage */
            localStorage.setItem('access_token', access_token)
            localStorage.setItem('access_token_expire', Date.now() + expires_in)
            localStorage.setItem('access_token_created', Date.now())

            localStorage.setItem('refresh_token', refresh_token)
            localStorage.setItem('refresh_token_expire', Date.now() + refresh_expires_in)
            localStorage.setItem('refresh_token_created', Date.now())
        }else{
            /* sessionStorage */
            sessionStorage.setItem('access_token', access_token)
            sessionStorage.setItem('access_token_expire', Date.now() + expires_in)
            sessionStorage.setItem('access_token_created', Date.now())

            sessionStorage.setItem('refresh_token', refresh_token)
            sessionStorage.setItem('refresh_token_expire', Date.now() + refresh_expires_in)
            sessionStorage.setItem('refresh_token_created', Date.now())
        }
    }

    @Mutation
    clearAuthData(){
        /* sessionStorage */
        sessionStorage.clear();
        
        /* localstorage */
        localStorage.clear();
    }

    @Action({rawError: true})
    async login({login, pass, remember_me, account_type, rememberme}) {
        console.debug(login, remember_me, account_type)
        const {access_token,refresh_token, expires_in, refresh_expires_in} = await $classes.AuthService.token({
            grant_type:"password", 
            username: login, 
            password: pass, 
            scope:"provider:Person"
        });
        
        await $classes.loadAuthenticated({token: access_token})
        const user = await $classes.Person.current_user();
        if (user.role != process.env.VUE_APP_USER_ROLE || user.person_language_locale_code !== process.env.VUE_APP_USER_LOCALE_CODE) throw {message: "You are not authorized to login to this service"}

        this.context.commit('clearAuthData')
        this.context.commit('saveAuthData', {
            access_token: access_token, 
            refresh_token:refresh_token,
            expires_in: expires_in,
            refresh_expires_in: refresh_expires_in,
            remember_me: rememberme
        })
        this.context.commit('setInstance', user)
    }

    @Action({rawError: true})
    async login_with_token() {
        let access_token_expire = 
            sessionStorage.getItem('access_token_expire') ? 
            sessionStorage.getItem('access_token_expire') : 
            localStorage.getItem('access_token_expire')

        if(Date.now() >= access_token_expire){ //  access_token expired
            console.debug('access_token expired')

            let refresh_token_expire = 
                sessionStorage.getItem('refresh_token_expire') ? 
                sessionStorage.getItem('refresh_token_expire') : 
                localStorage.getItem('refresh_token_expire')

            if(Date.now() < refresh_token_expire){ //  refresh_token okey
                let refresh_token_old = 
                    sessionStorage.getItem('refresh_token') ? 
                    sessionStorage.getItem('refresh_token') :
                    localStorage.getItem('refresh_token')
                
                const {access_token,refresh_token, expires_in, refresh_expires_in} = await $classes.AuthService.token({
                    grant_type: "refresh_token", 
                    refresh_token: refresh_token_old, 
                    scope: "provider:Person"
                })

                this.context.commit('clearAuthData')
                this.context.commit('saveAuthData', {
                    access_token: access_token, 
                    refresh_token: refresh_token,
                    expires_in: expires_in,
                    refresh_expires_in: refresh_expires_in,
                    remember_me: '?'
                })

                await $classes.loadAuthenticated({token: access_token})
                
                const user = await $classes.Person.current_user();
                this.context.commit('setInstance', user)

            }else console.debug('refresh_token expired')

        }else{ //  access_token okey
            let access_token = 
                sessionStorage.getItem('access_token') ? 
                sessionStorage.getItem('access_token') :
                localStorage.getItem('access_token')
            
            await $classes.loadAuthenticated({token: access_token})

            const user = await $classes.Person.current_user();
            this.context.commit('setInstance', user)
        }
    }

    @Action({rawError: true})
    async proxy_login({accessToken}) {
        await $classes.loadAuthenticated({token: accessToken})
        const user = await $classes.Person.current_user();

        this.context.commit('setInstance', user)
    }
    
    @Action({rawError: true})
    async logout() {
        console.debug('logout')
        await $classes.Person.logout()
        await $classes.loadPublic()
        
        this.context.commit('clearAuthData')
        this.context.commit('setInstance', null)
    }

    @Action({rawError: true})
    async send_auth_sms({phone, provider, account_type, realm}) {
        console.debug(phone, account_type)
        const {request_id} = await $classes.LoginSms.send_sms({phone, provider, account_type, realm});
        this.context.commit('setSmsRequestId', request_id)
    }
    
    @Action({rawError: true})
    async get_user_data() {
        const user = await $classes.Person.current_user();
        this.context.commit('setInstance', user)
    }

    @Action({rawError: true})
    async confirm_auth_sms({request_id, sms_code, remember_me, account_type, rememberme}) {
        // console.debug(request_id, sms_code, remember_me, account_type)
        const {access_token} = await $classes.LoginSms.token({request_id, sms_code, remember_me});
        
        this.context.commit('clearAuthData')
        this.context.commit('saveAuthData', {
            access_token: access_token, 
            refresh_token: refresh_token,
            expires_in: expires_in,
            refresh_expires_in: refresh_expires_in,
            remember_me: rememberme
        })

        await $classes.loadAuthenticated({token: access_token})
        const user = await $classes.Person.current_user();
        this.context.commit('setInstance', user)
    }

    @Action({rawError: true})
    async confirm_register_hash({hash}) {
        // console.debug(hash, account_type)
        if(!this.confirm_hash && !this.confirm_hash_in_progress){
            this.context.commit('setConfirmHash', {confirm_hash: null, status: 'in_progress'})
            const self = this

            await $classes.Person.energomocni_user_confirm_with_hash({hash}).then(function (data) {
                self.context.commit('setConfirmHash', {confirm_hash: data != null ? data.phone_verification : null, status: !data.phone_verification ? 'confirmed' : null})

                return {hash: data.phone_verification}
            })
        }

        return {hash: this.confirm_hash}
    }
    
    @Action({rawError: true})
    async confirm_register_sms({hash, sms_code}) {
        const auto_login = true;

        const {is_confirmed, token, new_hash} = await $classes.PersonConfirm.confirm_data({hash, sms_code, auto_login});

        if(new_hash)
            this.context.commit('setConfirmHash', {confirm_hash: new_hash, status: 'in_progress'})
        else if(token){
            const {access_token} = token;
            await $classes.loadAuthenticated({token: access_token})
            const user = await $classes.Person.current_user();
        }

        return {is_confirmed: is_confirmed, new_hash: new_hash};
    }
}

export default CurrentUser;